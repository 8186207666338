import React from 'react';
import Main from './main.js';


class Home extends React.Component {
render() {
  return (

    <div>
      <Main />
    </div>

  );
}
}

export default Home;
